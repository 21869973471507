const apiConfig = {
    b2cScopes: [
      'https://prdaadb2cbuzzebees.onmicrosoft.com/67de3668-5e07-4b36-b792-3b141e384823/Access',
    ],
  }
  const b2cPolicies = {
    names: {
      signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
      editProfile: 'B2C_1A_PROFILE_EDIT',
      changePassword: 'B2C_1A_PROFILE_EDIT_PASSWORD_CHANGE',
    },
    authorities: {
      signUpSignIn: {
        authority:
          'https://prdaadb2cbuzzebees.b2clogin.com/prdaadb2cbuzzebees.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
      },
      editProfile: {
        authority:
          'https://prdaadb2cbuzzebees.b2clogin.com/prdaadb2cbuzzebees.onmicrosoft.com/B2C_1A_PROFILE_EDIT',
      },
      changePassword: {
        authority:
          'https://prdaadb2cbuzzebees.b2clogin.com/prdaadb2cbuzzebees.onmicrosoft.com/B2C_1A_PROFILE_EDIT_PASSWORD_CHANGE',
      },
    },
    authorityDomain: 'prdaadb2cbuzzebees.b2clogin.com',
  }
  const local = 'my' // Malaysia (MY)
  export const config = {
    local: local,
    debug: false, //enable console.log
  
    // ------------- Production Settings ----------------
    api: 'api',
    adB2CUrl: `https://prd-api-active-directory-b2c-wrapper-${local}.azurewebsites.net/ppperm`,
    announcementManagementAPI: `https://api-product-announcement.buzzebees.com.${local}`,
    bzbBlobUrl: `https://prdproductization${local}.blob.core.windows.net`,
  
    // ------------------ End Settings ---------------------
    fbLogOnUrl: 'https://www.facebook.com/dialog/oauth?',
    fbPermissions: ['email'],
    firebase: {
      apiKey: '',
      authDomain: '',
      databaseURL: '',
      projectId: '',
      storageBucket: '',
      messagingSenderId: '',
      appId: '',
      measurementId: '',
    },
    msalConfig: {
      auth: {
        clientId: '3755462f-ce42-42b0-a6ac-7645e7d6949b',
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: window.location.origin + '/redirectms',
        postLogoutRedirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
    },
    b2cPolicies: b2cPolicies,
    loginRequest: {
      scopes: ['openid', ...apiConfig.b2cScopes],
      // prompt: 'select_account',
    },
    tokenRequest: {
      scopes: [...apiConfig.b2cScopes],
      forceRefresh: false,
    },
  
    businessRule: {
      css: {
        version: 4,
      },
    },
  }
  